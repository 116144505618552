<template>
    <v-container fill-height fluid text-xs-center>
        <v-card class="fill-height container--fluid" width="100vw" flat outlined>
            <v-card>
                <v-form ref="form" v-model="valid" lazy-validation>
                    <v-card-title class="text-h6 grey lighten-2">
                        <span class="headline">A&ntilde;adir Assisting Living</span>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text>
                        <v-container>
                            <v-row class="mt-2">
                                <v-col cols="10">
                                    <v-text-field v-model="editedItem.name" label="Nombre" :rules="textRules"
                                        :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')"
                                        required></v-text-field>
                                </v-col>
                                <v-col cols="2">
                                    <v-checkbox v-model="editedItem.active" label="Activo" v-if="user.roles == 'ADMIN'"
                                        :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')"></v-checkbox>
                                </v-col>

                                <v-col v-if="user.roles === 'ADMIN' || user.roles === 'CEETSB'" cols="5">
                                    <v-col cols="12" md="6">
                                        <v-select v-model="editedItem.state_ceetsb" dense
                                            :items="['Aprobado', 'Pendiente', 'Rechazado']"
                                            :rules="[v => !!v || 'Campo es obligatorio']" label="Excelencia"
                                            :disabled="saveChanges" item-color="primary" persistent-hint>
                                        </v-select>
                                    </v-col>
                                </v-col>
                                <v-col v-if="user.roles === 'ADMIN' || user.roles === 'CEETSB'" cols="7"
                                    class="d-flex align-center justify-space-between">
                                    <v-text-field dense v-model.number="editedItem.comment_ceetsb"
                                        :disabled="editedItem.state_ceetsb !== 'Rechazado' || saveChanges"
                                        label="Comentario" class="mt-3"></v-text-field>
                                    <v-icon>mdi-file-document</v-icon>
                                </v-col>

                                <v-col v-if="user.roles === 'ADMIN' || user.roles === 'COEPRIS'" cols="5">
                                    <v-col cols="12" md="6">
                                        <v-select v-model="editedItem.state_coepris" dense
                                            :items="['Aprobado', 'Pendiente', 'Rechazado']"
                                            :rules="[v => !!v || 'Campo es obligatorio']" label="Repssabi"
                                            :disabled="saveChanges" item-color="primary" persistent-hint>
                                        </v-select>
                                    </v-col>
                                </v-col>
                                <v-col v-if="user.roles === 'ADMIN' || user.roles === 'COEPRIS'" cols="7"
                                    class="d-flex align-center justify-space-between">
                                    <v-text-field dense v-model.number="editedItem.comment_coepris"
                                        :disabled="editedItem.state_coepris !== 'Rechazado' || saveChanges"
                                        label="Comentario" class="mt-3"></v-text-field>
                                    <v-icon>mdi-file-document</v-icon>
                                </v-col>

                                <v-col v-if="user.roles === 'ADMIN' || user.roles === 'COPARMEX'" cols="5">
                                    <v-col cols="12" md="6">
                                        <v-select v-model="editedItem.state_coparmex" dense
                                            :items="['Aprobado', 'Pendiente', 'Rechazado']"
                                            :rules="[v => !!v || 'Campo es obligatorio']" label="Coparmex"
                                            :disabled="saveChanges" item-color="primary" persistent-hint>
                                        </v-select>
                                    </v-col>
                                </v-col>
                                <v-col v-if="user.roles === 'ADMIN' || user.roles === 'COPARMEX'" cols="7"
                                    class="d-flex align-center justify-space-between">
                                    <v-text-field dense v-model.number="editedItem.comment_coparmex"
                                        :disabled="editedItem.state_coparmex !== 'Rechazado' || saveChanges"
                                        label="Comentario" class="mt-3"></v-text-field>
                                    <v-icon>mdi-file-document</v-icon>
                                </v-col>

                                <v-col cols="12">
                                    <v-radio-group v-model="editedItem.typeEntity">
                                        <v-row>
                                            <v-col cols="4">
                                                <v-radio
                                                    :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')"
                                                    label="Premium" value="P"></v-radio>
                                            </v-col>
                                            <v-col cols="4">
                                                <v-radio
                                                    :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')"
                                                    label="Miembro" value="M"></v-radio>
                                            </v-col>
                                            <v-col cols="4">
                                                <v-radio
                                                    :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')"
                                                    label="Básico" value="B"></v-radio>
                                            </v-col>
                                        </v-row>
                                    </v-radio-group>
                                </v-col>
                                <v-col cols="4">
                                    <v-checkbox v-model="editedItem.excellence"
                                        v-if="user.roles == 'CEETSB' || user.roles == 'ADMIN'" label="Excelencia"
                                        :disabled="saveChanges || saveChanges"></v-checkbox>
                                </v-col>

                                <v-col cols="4">
                                    <v-checkbox v-model="editedItem.repsabi"
                                        v-if="user.roles == 'COEPRIS' || user.roles == 'ADMIN'" label="Repssabi"
                                        :disabled="saveChanges || saveChanges"></v-checkbox>
                                </v-col>

                                <v-col cols="4">
                                    <v-checkbox v-model="editedItem.coparmex"
                                        v-if="user.roles == 'COPARMEX' || user.roles == 'ADMIN'" label="Coparmex"
                                        :disabled="saveChanges || saveChanges"></v-checkbox>
                                </v-col>
                                <v-col cols="4">
                                    <v-checkbox v-model="editedItem.promoted" :disabled="saveChanges"
                                        v-if="user.roles == 'COPARMEX' || user.roles == 'ADMIN'"
                                        label="Destacado"></v-checkbox>
                                </v-col>

                                <v-col cols="12">
                                    <v-combobox v-model="editedItem.address" :items="places" :loading="loadingPlaces"
                                        :search-input.sync="searchQuery" :rules="[
                                            (v) =>
                                                !!v || 'Dirección de ubicación obligatoria',
                                        ]" no-data-text="No hay resultados de búsqueda!" item-text="place_name"
                                        no-filter clearable required label="Dirección de residencia" v-on="$listeners"
                                        v-on:change="getSelectedItem"
                                        :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')"
                                        @click:clear="onClear">
                                    </v-combobox>
                                    <v-text-field v-model="editedItem.email" :rules="emailRules" label="E-mail"
                                        :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')"
                                        required></v-text-field>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-textarea v-model="editedItem.description" label="Descripción - Español"
                                        :rules="textRules"
                                        :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')"
                                        no-resize rows="6" counter required>
                                    </v-textarea>
                                </v-col>

                                <v-col cols="12" md="6">
                                    <v-textarea v-model="editedItem.description_english" label="Descripción - Inglés"
                                        :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')"
                                        :rules="textRules" no-resize rows="6" counter required>
                                    </v-textarea>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" md="6">
                                    <v-text-field-simplemask v-model="editedItem.phone" label="Teléfono"
                                        v-bind:properties="{
                                            dense: true,
                                            prefix: '+52',
                                            suffix: '',
                                            readonly: false,
                                            disabled: false,
                                            outlined: false,
                                            clearable: true,
                                            placeholder: '',
                                            disabled: saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX'),
                                            required: true,
                                            rules: textRules,
                                        }" v-bind:options="{
                                inputMask: '(###) ###-####',
                                outputMask: '##########',
                                empty: null,
                                applyAfter: false,
                                alphanumeric: false,
                                lowerCase: false,
                            }" v-bind:focus="focus" v-on:focus="focus = false" />
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-text-field dense v-model="editedItem.establishment_type"
                                        :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')"
                                        label="Tipo de Establecimiento"></v-text-field>
                                </v-col>
                                <!--<v-col cols="12" class="text-center mx-0">
                                                    <span class="text-decoration-underline">Datos de la Ubicación en Google Map</span>
                                                </v-col>
                                                <v-col cols="6">
                                                    <v-text-field-dotnumber
                                                            v-model.number="editedItem.map_lat"
                                                            label="Latitud"
                                                            v-bind:properties="{
                                                            dense: true,
                                                            readonly: false,
                                                            disabled: false,
                                                            outlined: false,
                                                            clearable: true,
                                                            placeholder: '',
                                                          }"
                                                            v-bind:options="{
                                                            length: 20,
                                                            empty: null,
                                                            applyAfter: false,
                                                        }"
                                                    />
                                                </v-col>
                                                <v-col cols="6">
                                                    <v-text-field-dotnumber
                                                            v-model.number="editedItem.map_lng"
                                                            label="Longitud"
                                                            v-bind:properties="{
                                                            dense: true,
                                                            readonly: false,
                                                            disabled: false,
                                                            outlined: false,
                                                            clearable: true,
                                                            placeholder: '',
                                                          }"
                                                            v-bind:options="{
                                                            length: 20,
                                                            empty: null,
                                                            applyAfter: false,
                                                        }"
                                                    />
                                                </v-col>-->
                                <v-col cols="12" md="6" v-if="editedItem.typeEntity != 'B'">
                                    <v-text-field dense v-model="editedItem.trial" type="date"
                                        :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')"
                                        label="Trial">
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-text-field dense v-model="editedItem.facebook_profile"
                                        :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')"
                                        label="Perfil en Facebook"></v-text-field>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-text-field dense v-model="editedItem.instagram_profile"
                                        :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')"
                                        label="Perfil en Instagram"></v-text-field>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-text-field dense v-model.number="editedItem.web_page" label="Página Web"
                                        :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')"></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field dense v-model="editedItem.servicehours" label="Horarios de Atención"
                                        :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')"
                                        :rules="textRules" required></v-text-field>
                                </v-col>
                            </v-row>
                            <ProvinceMunicipality @update-data="updateEmit" :saveChanges="saveChanges" />
                            <v-row>
                                <VideoAdd @updateVideo="updateVideo" :videos="editedItem.videos" action="ADD">
                                </VideoAdd>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" :disabled="saveChanges" text @click="close">
                            Cancelar
                        </v-btn>
                        <v-btn color="primary" :loading="saveChanges" :disabled="!valid" @click="save">
                            Guardar
                        </v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-card>
        <v-snackbar v-model="snackBar" :timeout="2000">
            {{ snackText }}
            <template v-slot:action="{ attrs }">
                <v-btn v-bind="attrs" icon @click="snackBar = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>
    </v-container>
</template>

<script>
import service from "@/providers/AssitingLivingService";
import VideoAdd from "@/components/admin/modules/components/VideoAdd";
import { changeInformationSEO } from "@/providers/KeywordsServices";
import ProvinceMunicipality from "@/components/province-municipality/ProvinceMunicipality";

export default {
    name: "AddAssitingAliving",
    components: {
        ProvinceMunicipality,
        VideoAdd
    },
    data() {
        return {
            placeNameLimit: 60,
            selectedPlace: {
                place_name: "",
                center: [],
            },
            placesList: [],
            loadingPlaces: false,
            searchQuery: null,
            mapBoxApiKey: process.env.VUE_APP_BHC_MAPBOX_API_KEY,
            focus: false,
            valid: true,
            snackBar: false,
            snackText: "",
            textRules: [(v) => !!v || "Este campo es obligatorio!"],
            emailRules: [
                (v) => !!v || "El Correo Electrónico es obligatorio",
                (v) => !/[A-Z,Ñ]+/.test(v) || "No se permiten letras en mayúsculas",
                (v) => /.+@.+\..+/.test(v) || "El Correo Electrónico debe ser válido",
            ],
            editedItem: {
                id: 0,
                name: "",
                description: "",
                description_english: "",
                establishment_type: "",
                email: "",
                phone: "",
                web_page: "",
                address: "",
                map_lat: 0,
                map_lng: 0,
                facebook_profile: "",
                instagram_profile: "",
                youtube_video: "",
                servicehours: "",
                pictures: [],
                coparmex: false,
                excellence: false,
                repsabi: false,
                state: 'Pendiente',
                comment: "",
                comment_ceetsb: "",
                comment_coparmex: "",
                comment_coepris: "",
                state_ceetsb: "Pendiente",
                state_coparmex: "Pendiente",
                state_coepris: "Pendiente",
                videos: [],
                typeEntity: "M",
                province: null,
                municipality: null,
                trial: "",
                promoted: false,
            },
            user: {},
            saveChanges: false,
        }
    },
    methods: {
        updateEmit(value) {
            this.editedItem = {
                ...this.editedItem,
                ...value
            }
        },
        updateVideo(videos) {
            this.editedItem.videos = videos;
        },
        queryReady(val) {
            return val !== null && val !== undefined && val !== "";
        },
        onClear() {
            this.$emit("input", null);
            this.$emit("clear");
        },
        async searchPlaces(query) {
            if (query === null || query.length === 0) return;
            let url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${query}.json?types=address&access_token=${this.mapBoxApiKey}`;
            this.loadingPlaces = true;
            fetch(url)
                .then((res) => res.json())
                .then((res) => {
                    const { query, features } = res;
                    this.query = query;
                    this.placesList = features;
                })
                .catch((err) => {
                    console.log("Error ejecutando API: ", err);
                })
                .finally(() => (this.loadingPlaces = false));
        },
        getSelectedItem(item) {
            this.selectedPlace = Object.assign({}, item);
        },
        close() {
            this.$router.push({
                name: 'AssitingLivingModule'
            });
        },
        save() {
            this.$refs.form.validate();
            if (this.$refs.form.validate(true)) {
                this.saveChanges = true;
                if (this.selectedPlace.center && this.selectedPlace.center.length > 0) {
                    this.editedItem.address = this.selectedPlace.place_name;
                    this.editedItem.map_lng = this.selectedPlace.center[0];
                    this.editedItem.map_lat = this.selectedPlace.center[1];
                }
                service.addRecord(this.editedItem).then((response) => {
                    if (response.data.success) {
                        this.close();
                        this.snackText = "Registro guardado con éxito...";
                    }
                    else {
                        this.snackText = response.data.message;
                    }
                }).catch(() => {
                    this.snackText = "Ha ocurrido un error";
                }).finally(() => {
                    this.snackBar = true;
                    this.saveChanges = false;
                });
            }
        }
    },
    computed: {
        places() {
            let listPlaces = [];
            if (this.placesList) {
                listPlaces = this.placesList.map((entry) => {
                    const PlaceName =
                        entry.place_name.length > this.placeNameLimit
                            ? entry.place_name.slice(0, this.placeNameLimit) + "..."
                            : entry.place_name;
                    console.log("PlaceName: ", PlaceName);
                    return Object.assign({}, entry);
                });
            }
            return listPlaces;
        },
    },
    watch: {
        searchQuery(val) {
            if (this.queryReady(val)) {
                this.searchPlaces(val);
            }
        },
    },
    mounted() {
        this.user = this.$store.getters.getUser;
        changeInformationSEO();
    }
}
</script>

<style scoped></style>